import React, { useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';

import PropTypes from 'prop-types';

import HamburgerButton from '@client/ClientEvents/sole24ore/HamburgerButton.jsx';
import SearchButton from '@client/ClientEvents/sole24ore/Search.jsx';
import UserButton, { UserButtonAnon, LogoutButtonHeader } from '@client/ClientEvents/sole24ore/UserButton.jsx';
import ModalLogin, { modalVisibility } from '@client/ClientEvents/sole24ore/Modal.jsx';
import { SidemenuBackdrop, SidemenuCloseButton, SidemenuVoce } from '@client/ClientEvents/sole24ore/Sidemenu.jsx';
import ModalSearch from '@client/ClientEvents/sole24ore/ModalSearch.jsx';
import { appendBackdrop } from '@client/ClientEvents/Common/index.jsx';
import AppContext from '@client/ClientEvents/Common/AppContextComponent.jsx';
import Meteo from '@client/ClientEvents/sole24ore/Meteo.jsx';

const HeaderEvents = () => {
  const config = useContext(AppContext);
  const { logged, shortname, section, sectionLink, subSectionLink, withHamburger, newDesign, withMeteo, customSubsections, errMsg, isLocked } = config;
  const hasCustomSubsections = customSubsections.length > 0;
  useEffect(() => {
    const onScroll = (e) => {
      const offset = document.body.getBoundingClientRect();
      const offsetTop = offset.y || offset.top;
      // cleanup("is-scrolling")
      let offsetTreshold = 250; // la testata di sezione e homepage sono più alte rispetto alle altre, quindi la sticky deve scendere dopo
      if (section === 'article' || section === 'subsection') {
        offsetTreshold = 15;
      }
      if (-offsetTop - offsetTreshold > 0) {
        document.body.classList.add('is-scrolled');
        if (offset.width >= 768)
          document.querySelector('.sticky-header').setAttribute('style', 'opacity: 1; -webkit-transform: translate3d(0,0,0); transform: translate3d(0,0,0); box-shadow: 0 2px 0 0 rgba(0,0,0,.15);');
        else document.querySelector('.main-header').setAttribute('style', 'width:100%; position:fixed');
      } else {
        document.body.classList.remove('is-scrolled');
        document.querySelector('.main-header').setAttribute('style', '');
        document.querySelector('.sticky-header').setAttribute('style', '');
      }
    };
    window.addEventListener('scroll', onScroll);
    // return () => { window.removeEventListener("scroll", onScroll); }
    appendBackdrop();
  }, []);

  useEffect(() => {
    // se avevo dei parametri di errore dal du in query string mostro direttamente la modale di login perché significa che c'è stato un problema.
    if (errMsg != '' && !isLocked && !logged) modalVisibility('show', 'block');
  });

  const closeButtons = Array.from(document.querySelectorAll('.sidemenu-close'));
  return (
    <React.Fragment>
      {ReactDOM.createPortal(<SearchButton newDesign={newDesign} noLabel={section === 'article' || section === 'subsection'} />, document.querySelector('#header-search-open'))}
      {ReactDOM.createPortal(<SearchButton newDesign={newDesign} noLabel />, document.querySelector('#header-search-open-sticky'))}
      {closeButtons.map((el) => ReactDOM.createPortal(<SidemenuCloseButton />, el))}
      {ReactDOM.createPortal(<ModalSearch />, document.querySelector('#modal_search_placeholder'))}
      {withHamburger ? <HamburgerElements newDesign={newDesign} section={section} /> : null}
      {(!logged && document.querySelector('#user_button_anon') && <AnonButtons newDesign={newDesign} />) ||
        (logged && document.querySelector('#user_button_logged') && <LoggedInButtons shortname={shortname} newDesign={newDesign} />)}
      {section === 'homepage' || (section === 'section' && !newDesign) ? <TopDropdowns /> : null}
      {document.querySelector('#sidemenu-backdrop-placeholder') ? ReactDOM.createPortal(<SidemenuBackdrop />, document.querySelector('#sidemenu-backdrop-placeholder')) : null}
      {sectionLink !== '' ? Array.from(document.querySelectorAll('a.sectionLink')).map((e) => e.setAttribute('href', sectionLink)) : null}
      {subSectionLink !== '' ? Array.from(document.querySelectorAll('a.subSectionLink')).map((e) => e.setAttribute('href', subSectionLink)) : null}
      {/* {hasCustomSubsections && newDesign ? ReactDOM.createPortal(<CustomSubsections list={customSubsections} newDesign={newDesign} />, document.querySelector('#customSubsections')) : null} */}
      {document.querySelector('#customSubsections') && ReactDOM.createPortal(<CustomSubsections list={customSubsections} newDesign={newDesign} />, document.querySelector('#customSubsections'))}
      {document.querySelector('#customSubsections-sticky') &&
        ReactDOM.createPortal(<CustomSubsections list={customSubsections} newDesign={newDesign} />, document.querySelector('#customSubsections-sticky'))}{' '}
      {withMeteo && document.getElementById('meteo-homepage') && ReactDOM.createPortal(<Meteo />, document.getElementById('meteo-homepage'))}
    </React.Fragment>
  );
};

const HamburgerElements = ({ newDesign, section }) => (
  <React.Fragment>
    {ReactDOM.createPortal(<SidemenuVoce />, document.querySelector('#sidemenu'))}
    {ReactDOM.createPortal(<HamburgerButton newDesign={newDesign} noLabel={section === 'article' || section === 'subsection'} />, document.querySelector('#header-hamburger'))}
    {ReactDOM.createPortal(<HamburgerButton newDesign={newDesign} noLabel />, document.querySelector('#header-hamburger-sticky'))}
    {ReactDOM.createPortal(<HamburgerButton type="mobile" />, document.querySelector('#header-hamburger_mobile'))}
  </React.Fragment>
);

const TopDropdowns = () => (
  <React.Fragment>
    {ReactDOM.createPortal(<DropdownSections />, document.querySelector('#dropdown_sect_header'))}
    {ReactDOM.createPortal(<DropdownSections />, document.querySelector('#dropdown_sect_header-sticky'))}
  </React.Fragment>
);

const DropdownSections = () => {
  const handler = (e) => {
    e.preventDefault();
    const postfix = e.currentTarget.parentNode.getAttribute('id').indexOf('-sticky') > -1 ? '-sticky' : '';
    document.querySelector(`#dropdown_sect_header${postfix} .dropdown-menu`).classList.toggle('show');
  };
  return (
    <button className="hlink" type="button" onClick={handler}>
      …
    </button>
  );
};

const AnonButtons = ({ newDesign }) => (
  <React.Fragment>
    {ReactDOM.createPortal(<UserButtonAnon newDesign={newDesign} />, document.querySelector('#user_button_anon'))}
    {document.querySelector('#user_button_anon-sticky') ? ReactDOM.createPortal(<UserButtonAnon newDesign={newDesign} />, document.querySelector('#user_button_anon-sticky')) : null}
    {document.querySelector('#user_button_anon_mobile') ? ReactDOM.createPortal(<UserButtonAnon newDesign={newDesign} />, document.querySelector('#user_button_anon_mobile')) : null}
    {ReactDOM.createPortal(<ModalLogin />, document.querySelector('#modal_login_placeholder'))}
  </React.Fragment>
);

const LoggedInButtons = ({ shortname, newDesign }) => (
  <React.Fragment>
    {ReactDOM.createPortal(<UserButton shortname={shortname} newDesign={newDesign} />, document.querySelector('#user_button_logged'))}
    {document.querySelector('#user_button_logged-sticky') && ReactDOM.createPortal(<UserButton shortname={shortname} newDesign={newDesign} />, document.querySelector('#user_button_logged-sticky'))}
    {document.querySelector('#user_button_logged_mobile') && ReactDOM.createPortal(<UserButton shortname={shortname} newDesign={newDesign} />, document.querySelector('#user_button_logged_mobile'))}
    {ReactDOM.createPortal(<LogoutButtonHeader />, document.querySelector('#user_button_logout_header'))}
    {document.querySelector('#user_button_logout_header-sticky') && ReactDOM.createPortal(<LogoutButtonHeader />, document.querySelector('#user_button_logout_header-sticky'))}
    {document.querySelector('#user_button_logout_header-sticky') && ReactDOM.createPortal(<LogoutButtonHeader />, document.querySelector('#user_button_logout_header_mobile'))}
  </React.Fragment>
);

const CustomSubsections = ({ list, newDesign, numItems }) => (
  <React.Fragment>
    {list
      .filter((item, index) => index < numItems)
      .map((item) => {
        const { link, label } = item;
        return (
          <li className={`${newDesign ? 'hnav-item' : 'nav-inline-item'}`} key={`sections-${label}`}>
            <a className="hlink" href={link}>
              {label}
            </a>
          </li>
        );
      })}
  </React.Fragment>
);

LoggedInButtons.propTypes = {
  shortname: PropTypes.string.isRequired,
};

CustomSubsections.defaultProps = {
  numItems: 5,
};

CustomSubsections.propTypes = {
  list: PropTypes.array.isRequired,
  newDesign: PropTypes.bool.isRequired,
  numItems: PropTypes.number,
};

export default HeaderEvents;
