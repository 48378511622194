import React from 'react';
import 'element-closest-polyfill';

const SidemenuBackdrop = () => {
  const handler = (e) => {
    e.preventDefault();
    document.body.classList.toggle('is-sidemenu-in');
  };
  return <span className="sidemenu-backdrop" onClick={handler} />;
};

const SidemenuCloseButton = () => {
  const handler = (e) => {
    e.preventDefault();
    document.body.classList.remove('is-sidemenu-in');
  };
  return <span className="icon icon-close" onClick={handler} />;
};

const SidemenuVoce = () => {
  let timer = null;

  const handleEvent = (e) => {
    const sidemenuMaxLevel = 4;

    // il target può essere qualsiasi elemento all'interno del link quindi bisognerà trovare il link stesso
    const { target } = e;

    const backButton = target.classList.contains('sidemenu-back') || target.classList.contains('icon-arrow-left');

    /* 
          Se è stato premuto il pulsante back: 

          - prendo il link che è stato premuto che ha la classe sidemenu-back

          Se è stato premuto un link: 
          - prendo il link premuto con classe sidemenu-link

    */
    const targetAnchor = backButton ? target.closest('.sidemenu-back') : target.closest('.sidemenu-link');

    if (!targetAnchor) {
      return null;
    }
    // livello dentro il quale si trova il link
    const thisLevel = targetAnchor.closest('.sidemenu-lev');
    // prossimo livello
    const nextLevelDepth = (parseInt(thisLevel.getAttribute('data-level'), 10) || 0) + 1;
    const timeout = e.type === 'mouseover' ? 400 : 0;

    if (targetAnchor.matches('[data-next-level]') || targetAnchor.matches('[data-prev-level]')) {
      e.preventDefault();
    }

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      // tolgo is-active ai link del livello corrente
      const thisLevelActives = thisLevel.querySelectorAll('.is-active');
      if (thisLevelActives && thisLevelActives.length > 0) {
        [...thisLevelActives].forEach((el) => el.classList.remove('is-active'));
      }

      // chiudo TUTTI i cassetti successivi al livello corrente
      for (let i = nextLevelDepth; i <= sidemenuMaxLevel; i += 1) {
        const currentLevelLevs = document.querySelectorAll(`[data-level='${i}'${']'}`);
        if (currentLevelLevs && currentLevelLevs.length > 0) {
          [...currentLevelLevs].forEach((el) => el.classList.remove('is-active'));
        }
      }

      if (targetAnchor.matches('[data-next-level]') || targetAnchor.matches('[data-prev-level]')) {
        /* 
          Se è stato premuto il pulsante back: 

          - prendo il lev corrente e trovo il suo id
          - prendo il link corrispondente all'id del lev (sarebbe l'ID del link nella voce precedente)
          - prendo il lev in cui sta il link trovato

          Se è stato premuto un link: 
          - trovo l'ID del link interessato
          - trovo il LEV che deve aprirsi in base all'ID
        
        */

        const targetLev = backButton
          ? document.querySelector(`#link-${targetAnchor.closest('.sidemenu-lev').id.split('lev-').pop()}`).closest('.sidemenu-lev')
          : document.querySelector(`#lev-${targetAnchor.id.split('link-').pop()}`);

        if (targetLev && (window.innerWidth >= 1024 || e.type !== 'mouseover')) {
          if (targetAnchor.matches('[data-prev-level]')) {
            // chiudo il livello corrente e resetto i suoi link attivi
            thisLevel.classList.remove('is-active');
            const targetLevActives = targetLev.querySelectorAll('.is-active');
            if (targetLevActives && targetLevActives.length > 0) {
              [...targetLevActives].forEach((el) => {
                el.classList.remove('is-active');
              });
            }
          }

          // Imposto lo stato attivo sul link corrente
          targetAnchor.classList.add('is-active');

          // Apro il lev associato al link
          targetLev.classList.add('is-active');
        }
      }
    }, timeout);
  };

  React.useEffect(() => {
    const items = document.querySelectorAll('.sidemenu-link, .sidemenu-action, .sidemenu-list-sep');

    [...items].forEach((item) => {
      item.addEventListener('mouseover', handleEvent);
      item.addEventListener('touchend', handleEvent);
      item.addEventListener('click', handleEvent);
    });

    return () => {
      [...items].forEach((item) => {
        item.removeEventListener('mouseover', handleEvent);
        item.removeEventListener('touchend', handleEvent);
        item.removeEventListener('click', handleEvent);
      });
    };
  });

  return null;
};

export { SidemenuBackdrop, SidemenuCloseButton, SidemenuVoce };
