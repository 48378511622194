import crypto from 'crypto';

import UAParser from 'ua-parser-js';
import { setCookie, parseCookies } from 'nookies';

import { websites } from '../../../../Components/defaults.jsx';

export { websites };

export const plus24Domain = 'https://24plus.ilsole24ore.com';
export const soleHost = 'https://www.ilsole24ore.com';
export const duHost = 'https://du.ilsole24ore.com';
export const R24Url = 'https://www.ricerca24.ilsole24ore.com/fc';

export const meteoUrl = 'https://meteo24.ilsole24ore.com/assets/widget/ilsole24.min.js';

const isRelativeScript = /^\/[^/]/;
export function loadScript(script, id, callback) {
  const aScript = document.createElement('script');
  if (id) {
    const scriptid = document.getElementById(id);
    if (scriptid) {
      document.head.removeChild(scriptid);
    }
    aScript.id = id;
  }
  if (callback) {
    aScript.onload = () => {
      const state = aScript.readyState;
      if (!callback.done && (!state || /loaded|complete/.test(state))) {
        callback.done = true;
        callback();
      }
    };
    aScript.onreadystatechange = aScript.onload;
  }
  aScript.src = isRelativeScript.test(script) ? `${getCdnJavascriptHost()}${script}` : script;
  aScript.async = true;
  aScript.setAttribute('async', '');
  aScript.setAttribute('defer', '');
  aScript.type = 'text/javascript';
  document.head.appendChild(aScript);
}

export function socialLogin(noloop = false) {
  if (typeof showSocialButtons !== 'undefined' /*  && document.getElementById(window._providersContainerID ) */) {
    window.showSocialButtons('CO', '', 'home2019.css');
  } else {
    if (noloop) {
      return;
    }
    loadScript(`${duHost}/utenti/AuthFiles/SocialLogin/DUSocialLoginJS.ashx`, 'sociallogin', () => {
      socialLogin(true);
    });
  }
}

export const appendBackdrop = (backdropClass = 'modal-backdrop', style = '') => {
  const backdrop = document.createElement('div');
  backdrop.classList.add(backdropClass);
  backdrop.setAttribute('style', `display:none;${style}`);
  document.body.appendChild(backdrop);
};

export const promiseTimeout = function (ms, promise) {
  // Create a promise that rejects in <ms> milliseconds
  const timeout = new Promise((resolve, reject) => {
    const id = setTimeout(() => {
      clearTimeout(id);
      reject(new Error(`Timed out in ${ms} ms.`));
    }, ms);
  });

  // Returns a race between our timeout and the passed in promise
  return Promise.race([promise, timeout]);
};

// se l'utente non ha i permessi, viene impostata una scadenza del cookia a 5 minuti, se ha i permessi, fino a fine giornata
export function setSoleCookie(name, value, short = false, remove) {
  const date = new Date();
  const expireTime =
    (remove && new Date(date.getTime() - 60 * 1000)) || (short && new Date(date.getTime() + 5 * 60 * 1000)) || new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
  setCookie({}, name, value, {
    expires: expireTime,
    path: '/',
    domain: '.ilsole24ore.com',
  });
}

const algorithm = 'aes-256-cbc';
const key = 'chf.ilsole24ore.com/headerfooter';
const iv = 'sole24ore/header';

export function encrypt(text) {
  const cipher = crypto.createCipheriv(algorithm, Buffer.from(key), iv);
  const encrypted = cipher.update(text);
  return Buffer.concat([encrypted, cipher.final()]).toString('hex');
}

export function decrypt(text) {
  const encryptedText = Buffer.from(text, 'hex');
  const decipher = crypto.createDecipheriv(algorithm, Buffer.from(key), iv);
  const decrypted = decipher.update(encryptedText);
  return Buffer.concat([decrypted, decipher.final()]).toString();
}

export function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

/* export const renderIndestructibleModal = (config, me) => {
  const { website, rootName } = config;
  ReactDOM.render(<ClientPayEvents website={website} me={me} />, document.getElementById(rootName));
} */

export const getDeviceType = (ua) => {
  const uaParser = new UAParser();
  uaParser.setUA(ua);
  const deviceType = uaParser.getDevice().type;
  if (!deviceType) {
    return 'desktop';
  }
  return deviceType;
};

export const createNode = (idName, className = '', style = '') => {
  if (!document.getElementById(idName)) {
    //console.log("creo nuovo element")
    const elem = document.createElement('div');
    elem.setAttribute('id', idName);
    elem.setAttribute('class', 's24_centralized ' + className);
    elem.setAttribute('style', style)
    document.body.appendChild(elem);
  }
  //console.log(document.getElementById(idName))
  return document.getElementById(idName);
};

export const getSubscribeUrl = () => {
  return 'https://abbonamenti.ilsole24ore.com';
  /* switch (getEnvironment()) {
    case 'delivery':
      return 'https://abbonamenti-dlv.ilsole24ore.com';
    case 'production':
      return 'https://abbonamenti.ilsole24ore.com';
    default:
      return 'http://abbonamenti.nile.dev.24orepro.in.ilsole24ore.it';
  } */
};


export const checkSoleA = (value, operation) => {
  // operation = update o remove
  // value = 0 non ha permessi, 1 ha permessi
  const cookies = parseCookies();
  const isAlreadyPresent = cookies.SoleCHFA !== undefined;
  const consent = cookies.SoleCookieAccept !== undefined; //epubconsent-v2
  // console.log("flag: ", isAlreadyPresent, consent, operation)
  // console.log("c'è o no", isAlreadyPresent, cookies['SoleCHFA'])
  if (consent) {
    // console.log("entro consent, set SoleCHFA")
    setSoleCookie('SoleCHFA', value, false, operation === 'remove');
    if (!isAlreadyPresent && operation !== 'remove') {
      //console.log("faccio reload!!!!")
      document.location.reload();
    }
  }
}