import React from 'react';
import { cleanup } from '@client/ClientEvents/plus24/utils.jsx';
const HamburgerButton = () => {
  const handler = (e) => {
    const { body } = document;
    cleanup("menu-open");
    body.classList.toggle("is-menu-open")
  }
  return (
    <button className="ltool" onClick={handler}>
      <span className="icon icon-menu"></span>
    </button>
  )
}

export default HamburgerButton;