import React, { useContext, useEffect } from 'react';
import AppContext from '@client/ClientEvents/Common/AppContextComponent.jsx';
import { showLoginOverPay } from '@client/ClientEvents/Common/Modal.jsx';
import { getSubscribeUrl } from '@client/ClientEvents/Common/index.jsx';

const ModalLockPlus = () => {
  const config = useContext(AppContext);
  const { logged, device, errMsg } = config;
  

  useEffect(() => { 
    if (errMsg !== '') showLoginOverPay();
  }, []);

  const closeLockModal = (e) => {
    e.stopPropagation();
    e.preventDefault();
    // modalVisibility("hide", "display:none", "#modal-pay", ".modal-pay-backdrop");
    showLoginOverPay()
  };
  const promozione = (e) => {
    document.location = `${getSubscribeUrl()}/addToCart?sku=24&tipoAbbonamento=annuale&tipoConsegna=noConsegna&utm_campaign=19WEB33}`;
  };
  const styleOverflow = device === 'mobile' ? { overflowY: 'scroll' } : {};

 
  return (
    <div className="modal" tabIndex="-1" role="dialog" id="modal-pay" style={styleOverflow}>
      <div className="modal-dialog modal-dialog-centered modal-dialog--sans modal-lg">
        <div className="modal-content">
          <div className="modal-header modal-header--cc">
            <h3 className="lock-title lock-title--n">
              Continua a leggere su 24
              <span className="orange">+</span>
              , la sezione
              <br className="d-none d-md-block" />
              premium de Il&nbsp;Sole&nbsp;24&nbsp;Ore
            </h3>
          </div>

          <div className="modal-body">
            <div className="lock-description">
              <figure className="lock-description-img ">
                <img className="img-fluid" src="https://24plus.ilsole24ore.com/img/24plus-devices.png" alt="" />
              </figure>
              <span className="lock-offer-claim">Abbonandoti accedi subito a</span>
              <ul className="list">
                <li className="list-item">
                  <span className="lock-offer-plus">
                    <span className="icon icon-check-o" />
                    tutti i contenuti esclusivi
                  </span>
                </li>

                <li className="list-item">
                  <span className="lock-offer-plus">
                    <span className="icon icon-check-o" />
                    la newsletter 24+Recap
                  </span>
                </li>

                <li className="list-item">
                  <span className="lock-offer-plus">
                    <span className="icon icon-check-o" />
                    le serie podcast
                  </span>
                </li>

                <li className="list-item">
                  <span className="lock-offer-plus">
                    <span className="icon icon-check-o" />
                    gli audioarticoli letti dai giornalisti
                  </span>
                </li>

                <li className="list-item">
                  <span className="lock-offer-plus">
                    <span className="icon icon-check-o" />
                    le funzioni per la community
                  </span>
                </li>
              </ul>
            </div>

            <div className="lock-offer lock-offer--n">
              <span className="lock-offer-claim">Offerta speciale</span>

              <h4 className="lock-offer-title lock-offer-title--n">
                1 mese di 24
                <span className="orange">+</span>
{' '}
a solo 1 euro
</h4>
              <p className="lock-offer-disclaimer">
                Rinnovo
                <strong>9,90 €/mese</strong>
                oppure
                <strong>99 €/anno</strong>, automatico e disattivabile quando vuoi
</p>

              <div className="lock-offer-btn-wrapper">
                <button type="button" className="btn lock-offer-btn" onClick={promozione}>
                  Procedi per attivare l'offerta
                </button>

                {/* <span className="lock-offer-alt">
                  <span className="lock-offer-alt-text">Oppure</span>
                </span> */}

                {/* <a href="#" className="swg-button-light" id="swg-standard-button" lang="it-IT" style="width: 100%; height: 48px; padding-top: 15px; padding-bottom: 15px;" /> */}
              </div>
              <a className="btn btn--txt d-block d-md-none" href="https://24plus.ilsole24ore.com/faq">
                Perché abbonarsi a 24
                <span className="orange">+</span>
              </a>
            </div>
          </div>

          <div className="modal-footer lock-foot">
            <a className="btn btn--txt d-none d-md-inline-block" href="https://24plus.ilsole24ore.com/faq">
              Perché abbonarsi a 24
              <span className="orange">+</span>
            </a>
            {!logged ? (
              <div className="lock-foot-action">
                <span className="lock-label">Sei già abbonato?</span>
                <button type="button" className="btn" href="#" onClick={closeLockModal}>
                  Accedi
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalLockPlus;
