import React from 'react';
import PropTypes from 'prop-types';

import { modalVisibility } from '@client/ClientEvents/sole24ore/Modal.jsx';
import { duHost } from '@client/ClientEvents/plus24/utils.jsx';

const UserButton = ({ shortname, newDesign }) => {
  const handler = (e) => {
    e.preventDefault();
    let prefix = ''; // testata normale
    if (e.currentTarget.parentNode.getAttribute('id').indexOf('-sticky') > -1) prefix = '-sticky';
    else if (e.currentTarget.parentNode.getAttribute('id').indexOf('_mobile') > -1) prefix = '_mobile';
    const selector = prefix != '' ? `.${prefix}.huser.dropdown` : '.huser.dropdown';
    document.querySelector(selector).classList.toggle('is_expanded');
    document.querySelector(`#user_dropdown${prefix}`).classList.toggle('show');
  };
  const classPrefix = newDesign ? 'hicon' : 'huser';
  return (
    <button className={`${classPrefix}-link`} type="button" title="Nome Cognome" data-toggle="dropdown" onClick={handler}>
      {!newDesign && <span className={`${classPrefix}-label`}>{shortname}</span>}
      <span className="icon icon--multi icon-user-logged">
        <span className="path1" />
        <span className="path2" />
      </span>
    </button>
  );
};

export const LogoutButtonHeader = () => (
  <a className="btn btn--blue" href={`${duHost}/du/authfiles/logoff.aspx?RURL=${document.location}`}>
    Logout
  </a>
);

export const UserButtonAnon = ({ newDesign, isInvisible }) => {
  const handler = (e) => {
    e.preventDefault();
    modalVisibility('show', 'block');
  };
  const classPrefix = newDesign ? 'hicon' : 'huser';
  const style = isInvisible ? { visibility: 'hidden', position: 'fixed', bottom: 0, right: 0 } : {};
  return (
    <button className={`${classPrefix}-link`} type="button" data-toggle="modal" onClick={handler} style={style}>
      <span className={`${classPrefix}-label`}>Accedi</span>
      {/* ho tolto la if del newDesign per la label accedi perché vedo in grafica che c'è sempre */}
      <span className="icon icon-user" />
    </button>
  );
};

UserButton.propTypes = {
  shortname: PropTypes.string.isRequired,
  newDesign: PropTypes.bool.isRequired,
};

UserButtonAnon.propTypes = {
  newDesign: PropTypes.bool.isRequired,
  isInvisible: PropTypes.bool,
};

UserButtonAnon.defaultProps = {
  isInvisible: false,
};

export default UserButton;
