import React, {useContext} from 'react';
import { modalVisibility } from '@client/ClientEvents/sole24ore/Modal.jsx';
import { R24Url } from '@client/ClientEvents/Common/index.jsx';
import AppContext from '@client/ClientEvents/Common/AppContextComponent.jsx';

const ModalSearch = () => {
  const config = useContext(AppContext);
  const { device } = config;
  const [inputValue, setInputValue] = React.useState('');
  const closeHandler = (e) => {
    e.preventDefault();
    modalVisibility('hide', 'none', '#modal-search');
  };

  const handlerOnChange = (e) => {
    setInputValue(e.currentTarget.value);
  };

  function handleSubmitForm(e) {
    e.preventDefault();
    if (searchInput.length === 0) {
      return;
    }
    document.getElementById('ricercaForm').submit();
  }

  const styleOverflow = device === 'mobile' ? { overflowY: 'scroll' } : {};
  return (
    <div id="modal-search" className="modal modal--transparent" role="dialog" style={styleOverflow}>
      <div className="modal-dialog modal-lg" data-toggle-class-target>
        <div className="modal-content">
          <div className="modal-header">
            <a className="sidemenu-parent sidemenu-action d-md-none" href="#">
              <span className="icon icon-24" />
            </a>
            <a href="#" className="ltool ltool--circle ltool--clr ml-auto" aria-label="Close" onClick={closeHandler}>
              <span className="icon icon-close" />
            </a>
          </div>

          <div className="modal-body">
            <div className="search-panel-simple">
              <form id="ricercaForm" action={R24Url} methode="get" acceptCharset="ISO-8859-1">
                <input type="hidden" name="cmd" value="static" />
                <input type="hidden" name="chId" value="30" />
                <input type="hidden" name="path" value="/search/search_engine.jsp" />
                <input type="hidden" name="field" value="Titolo|Testo" />
                <input type="hidden" name="orderBy" value="score desc" />
                <input type="hidden" name="chId" value="30" />
                <div className="search-input">
                  <input className="input input--lined" type="text" name="keyWords" value={inputValue} placeholder="Cosa stai cercando?" onChange={handlerOnChange} />
                  <button className="search-input-submit" type="submit" name="button">
                    <span className="icon icon-search" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalSearch;
