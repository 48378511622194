import Cors from 'cors';

export const s24Defaults = {
  website: 'sole24ore', // sole24ore | 24plus
  type: 'lg', // xs, m, lg in accordo con le testate disponibili per quel website.
  section: 'homepage',
  header: true, // true | false - mostrare la header
  footer: true, // true | false - mostrare il footer
  isLocked: false, // contenuto protetto devo controllare autorizzazioni; was: checkAuth
  template: 'normal', // normal || large -> normal è il css con breakpoint 1440, large con bp 1540. 
  withHamburger: true, // opzione in caso vogliamo spegnere il menù per alleggerire la testata. Solo header Sole
  newDesign: false,
  hotTrends: true,
  //subSectionName: 'Attualità', 
  //sectionName: 'Italia',
};

export const websites = {
  SOLE_WEB: 'sole24ore',
  PLUS24: 'plus24',
};

export const cors = Cors({
  origin: 'http://webdev.ilsole24ore.com',
  methods: ['GET', 'HEAD'],
  credentials: true,
});

export const cssSole = [
  "/css/style-header-footer.css"
];
export const cssPlus24 = [
  '/css/style-header-footer-24plus.css'
];

export const topLinksList = [
  {
    label: 'Letti',
    url: '/piuletti',
  },
  {
    label: 'Report',
    url: '/art/tutti-report-24-gli-abbonati-ADsmjWE',
  },
  {
    label: 'Podcast',
    url: '/podcast',
  },
];

export const headersAllowed = {
  plus24: ['article', 'homepage'],
  ilsole24ore: ['article', 'homepage', 'section'],
};

export const plus24Domain = 'https://24plus.ilsole24ore.com';
export const sole24Domain = 'https://www.ilsole24ore.com';
export const duHost = 'https://du.ilsole24ore.com';
export const radio24Domain = 'http://www.radio24.ilsole24ore.com/';
export const professionistiDomain = 'https://24oreprofessionale.ilsole24ore.com/';
export const quotidianoUrl = 'https://www.quotidiano.ilsole24ore.com';

export const linkLanding = 'https://st.ilsole24ore.com/landingAbbonamenti/landing.shtml';
