import React from 'react';
import PropTypes from 'prop-types';

import { modalVisibility } from '@client/ClientEvents/sole24ore/Modal.jsx';

const SearchButton = ({newDesign, noLabel}) => {
  const handler = (e) => {
    e.preventDefault();
    document.body.classList.toggle('modal-open');
    document.body.classList.toggle('modal-search-open');
    modalVisibility('show', 'block', '#modal-search');
  };
  const buttonClassName = newDesign ? 'hicon-link' : 'hlink hlink--icon no-border no-outline';
  return (
    <button className={buttonClassName} type="button" onClick={handler}>
      {newDesign && !noLabel && <span className="hicon-label">Ricerca</span>}
      <span className="icon icon-search" />
    </button>
  );
};

SearchButton.defaultProps = {
  newDesign: false,
  noLabel: false,
};

SearchButton.propTypes = {
  newDesign: PropTypes.bool,
  noLabel: PropTypes.bool,

};

export default SearchButton;
